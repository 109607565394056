import Image, { StaticImageData } from 'next/image';

import { useEffect, useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import style from './style.module.css';

import { useSearchProvider } from 'providers/SearchProvider';
import ChatGPTLogo from 'public/images/chatgpt-logo.png';
import ClaudeLogo from 'public/images/claude-logo.svg?url';
import GeminiLogo from 'public/images/gemini-logo.svg?url';
import CleeAILogo from 'public/images/icons/cleeaiLogoV2.svg?url';
import MistralLogo from 'public/images/mistral-logo.svg?url';

import Markdown from 'react-markdown';

import Modal from 'components/Modal';

function LlmAnswerBox({
  logo,
  className,
  answer,
  isLoading = false,
  isDarkMode = false,
}: {
  logo: StaticImageData | string;
  className?: string;
  answer: string;
  isLoading?: boolean;
  isDarkMode?: boolean;
}) {
  return (
    <div className={className}>
      <Image src={logo} className={style.answerBoxLogo} alt="Logo" />
      <div className={style.answerBoxText}>
        {isLoading ? (
          <SkeletonTheme
            baseColor={isDarkMode ? '#333' : '#eee'}
            highlightColor={isDarkMode ? '#555' : '#f5f5f5'}
          >
            <Skeleton count={20} />
          </SkeletonTheme>
        ) : (
          <Markdown
            className={style.markdownText}
            components={{
              a: ({ href, children, ...props }) => (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...props}
                >
                  {children}
                </a>
              ),
            }}
          >
            {answer}
          </Markdown>
        )}
      </div>
    </div>
  );
}

interface LlmComparisonModalProps {
  isModalOpen: boolean;
  onCloseCB: () => void;
}

export default function LlmComparisonModal({
  isModalOpen,
  onCloseCB,
}: LlmComparisonModalProps) {
  const searchProvider = useSearchProvider();
  const questionAnswers = searchProvider?.search.getQuestionsAndAnswers();
  const firstQA =
    questionAnswers && questionAnswers.length > 0 ? questionAnswers[0] : null;

  const question = firstQA?.getQuestion();
  const cleeaiAnswer = firstQA?.getAnswer();
  const title = question ?? 'Compare with LLMs';
  const [lLMAnswerLoading, setLLMAnswerLoading] = useState({
    chatgpt: false,
    mistral: false,
    claude: false,
  });

  const fetchLLMAnswer = async (apiUrl: string, question: string) => {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ prompt: question }),
    });

    const data = await response.json();
    return data.content;
  };

  const fetchData = async () => {
    try {
      setLLMAnswerLoading({ chatgpt: true, mistral: true, claude: true });
      const [chatgptResult, mistralResult, claudeResult] = await Promise.all([
        fetchLLMAnswer('/api/chatgpt', question ?? ''),
        fetchLLMAnswer('/api/mistral', question ?? ''),
        fetchLLMAnswer('/api/claude', question ?? ''),
      ]);

      searchProvider?.search.setChatGPTAnswer(chatgptResult);
      searchProvider?.search.setMistralAnswer(mistralResult);
      searchProvider?.search.setClaudeAnswer(claudeResult[0].text);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLLMAnswerLoading({ chatgpt: false, mistral: false, claude: false });
    }
  };

  useEffect(() => {
    if (question && question.trim() !== '') {
      fetchData();
    }
  }, [question]);

  const closeModal = () => {
    onCloseCB();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onClose={closeModal}
      className={style.llmComparisonModal}
      showCloseButton={true}
      title={title}
    >
      <div className={style.llmComparisonModalContent}>
        <LlmAnswerBox
          logo={CleeAILogo}
          className={style.gridItemCleeai}
          answer={cleeaiAnswer ?? 'No answer found'}
        ></LlmAnswerBox>
        <LlmAnswerBox
          logo={ChatGPTLogo}
          className={style.gridItemChatgpt}
          answer={
            searchProvider?.search.getChatGPTAnswer(true) ?? 'No answer found'
          }
          isLoading={lLMAnswerLoading.chatgpt}
          isDarkMode={true}
        ></LlmAnswerBox>
        <LlmAnswerBox
          logo={MistralLogo}
          className={style.gridItemMistral}
          answer={
            searchProvider?.search.getMistralAnswer(false) ?? 'No answer found'
          }
          isLoading={lLMAnswerLoading.mistral}
        ></LlmAnswerBox>
        <LlmAnswerBox
          logo={GeminiLogo}
          className={style.gridItemGemini}
          answer={'Coming soon!'}
          isDarkMode={true}
        ></LlmAnswerBox>
        <LlmAnswerBox
          logo={ClaudeLogo}
          className={style.gridItemClaude}
          answer={
            searchProvider?.search.getClaudeAnswer(false) ?? 'No answer found'
          }
          isLoading={lLMAnswerLoading.claude}
        ></LlmAnswerBox>
      </div>
    </Modal>
  );
}
