import type { ModalCloseEvent, ModalProps } from 'components/Modal/Modal';
import { StateItem, useStateItem } from './stateItem';

export class Modal {
  constructor(
    private content: StateItem<React.ReactNode>,
    private title: StateItem<string | undefined>,
    private isOpen: StateItem<boolean>,
    private onClose: StateItem<((e: ModalCloseEvent) => void) | undefined>,
    private className: StateItem<string | undefined>,
    private showCloseButton: StateItem<boolean>,
  ) {}

  /**
   * Get the content for modal
   * @param latest: boolean
   * @returns React.ReactNode
   */
  private getContent(latest: boolean): React.ReactNode {
    return this.content.get(latest);
  }

  /**
   * Set the content for modal
   * @param latest: React.ReactNode
   * @returns this
   */
  public setContent(value: React.ReactNode): this {
    this.content.set(value);
    return this;
  }

  /**
   * Get the title for modal
   * @param latest: boolean
   * @returns string | undefined
   */
  private getTitle(latest: boolean): string | undefined {
    return this.title.get(latest);
  }

  /**
   * Set the title for modal
   * @param value: string | undefined
   * @returns this
   */
  public setTitle(value: string | undefined): this {
    this.title.set(value);
    return this;
  }

  /**
   * Get the isOpen for modal
   * @param latest: boolean
   * @returns boolean
   */
  public getIsOpen(latest: boolean): boolean {
    return this.isOpen.get(latest);
  }

  /**
   * Set the isOpen for modal
   * @param value: boolean
   * @returns this
   */
  public setIsOpen(value: boolean): this {
    this.isOpen.set(value);
    return this;
  }

  /**
   * Get the onClose for modal
   * @param latest: boolean
   * @returns (e: ModalCloseEvent) => void
   */
  private getOnClose(
    latest: boolean,
  ): ((e: ModalCloseEvent) => void) | undefined {
    return this.onClose.get(latest);
  }

  /**
   * Set the onClose for modal
   * @param value: (e: ModalCloseEvent) => void | undefined
   * @returns this
   */
  public setOnClose(value: ((e: ModalCloseEvent) => void) | undefined): this {
    this.onClose.set(() => value);
    return this;
  }

  /**
   * Get the className for modal
   * @param latest: boolean
   * @returns string | undefined
   */
  private getClassName(latest: boolean): string | undefined {
    return this.className.get(latest);
  }

  /**
   * Set the className for modal
   * @param value: string | undefined
   * @returns this
   */
  public setClassName(value: string | undefined): this {
    this.className.set(value);
    return this;
  }

  /**
   * Get the showCloseButton for modal
   * @param latest: boolean
   * @returns boolean
   */
  private getShowCloseButton(latest: boolean): boolean {
    return this.showCloseButton.get(latest);
  }

  /**
   * Set the showCloseButton for modal
   * @param value: boolean
   * @returns this
   */
  public setShowCloseButton(value: boolean): this {
    this.showCloseButton.set(value);
    return this;
  }

  /**
   * Get the modal props
   * @param latest: boolean
   * @returns ModalProps
   */
  public _getModalProps(latest: boolean): ModalProps {
    return {
      title: this.getTitle(latest),
      isOpen: this.getIsOpen(latest),
      onClose: (e: ModalCloseEvent) => {
        this.setIsOpen(false);
        this.getOnClose(latest)?.(e);
      },
      children: this.getContent(latest),
      className: this.getClassName(latest),
      showCloseButton: this.getShowCloseButton(latest),
    };
  }

  /**
   * Clear all modal values
   * @returns this
   */
  public clear() {
    this.content.set(undefined);
    this.title.set(undefined);
    this.isOpen.set(false);
    this.onClose.set(undefined);
    this.className.set(undefined);
    this.showCloseButton.set(false);
    return this;
  }
}

export function useModal() {
  return new Modal(
    useStateItem(undefined), // content
    useStateItem(undefined), // title
    useStateItem(false), // isOpen
    useStateItem(undefined), // onClose
    useStateItem(undefined), // className
    useStateItem(false), // showCloseButton
  );
}
